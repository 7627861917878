import React, { useState } from "react";
import HeroSection from "./components/HeroSection";
import ProblemSolution from "./components/ProblemSolution";
import CTASection from "./components/CTASection";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import Header from "./components/Header";


function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle('dark');
  };

  return (
    
      <div className="overflow-x-hidden">
      {/* Use the new Header component */}
      <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} />

      <HeroSection />
      <ProblemSolution />
      <Testimonials />
      <CTASection />
      <Footer />
    </div>
  );
}

export default App;
