import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'; // Font Awesome Icons for mobile menu

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white py-4 px-8 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <div className="logo">
          <h1 className="text-2xl font-bold text-black">Nextex</h1> {/* Replace with logo */}
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            {isOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
          </button>
        </div>

        {/* Navigation Links */}
        <nav className={`space-x-8 md:flex hidden`}>
          <a href="#services" className="text-black hover:text-red-500 transition-colors">Software Services</a>
          <a href="#case-studies" className="text-black hover:text-red-500 transition-colors">Case Studies</a>
          <a href="#pricing" className="text-black hover:text-red-500 transition-colors">Pricing</a>
          <a href="#about" className="text-black hover:text-red-500 transition-colors">About Us</a>
          <a href="#blog" className="text-black hover:text-red-500 transition-colors">Blog</a>
        </nav>

        {/* Contact Us CTA */}
        <div className="cta hidden md:block">
          <a href="#contact" className="bg-red-500 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:bg-red-600 transition-all">
            Contact Us →
          </a>
        </div>
      </div>

      {/* Mobile Menu Dropdown */}
      {isOpen && (
        <div className="md:hidden mt-4">
          <nav className="flex flex-col space-y-4">
            <a href="#services" className="text-black hover:text-red-500 transition-colors">Software Services</a>
            <a href="#case-studies" className="text-black hover:text-red-500 transition-colors">Case Studies</a>
            <a href="#pricing" className="text-black hover:text-red-500 transition-colors">Pricing</a>
            <a href="#about" className="text-black hover:text-red-500 transition-colors">About Us</a>
            <a href="#blog" className="text-black hover:text-red-500 transition-colors">Blog</a>
            <a href="#contact" className="bg-red-500 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:bg-red-600 transition-all">
              Contact Us →
            </a>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Header;
