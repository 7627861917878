// HeroSection.js
import Lottie from "lottie-react";
import aiAnimation from "../assets/ai-animation.json"; // Ensure the path is correct

function HeroSection() {
  return (
    <section className="bg-gradient-to-br from-gray-800 to-gray-900 text-white py-20 px-6 md:px-12" id="hero">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2">
          <h1 className="text-6xl font-extrabold text-white leading-snug font-poppins">
          Unlock the Future of AI Optimized Content
          </h1>
          <p className="text-lg leading-relaxed mb-8">
          Nextex helps traditional websites stay relevant by preparing content for AI consumption.
          </p>
          <a
            href="#cta"
            className="bg-teal-500 hover:bg-teal-600 text-white font-bold py-4 px-8 rounded-lg shadow-lg transition-all transform hover:scale-105">
            Get Started
          </a>
        </div>

        <div className="md:w-1/2 mt-10 md:mt-0 flex justify-center">
          <Lottie animationData={aiAnimation} loop={true} className="max-w-full" />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
