function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-8">
  <div className="container mx-auto flex justify-between items-center">
    <p>&copy; 2024 Nextex. All rights reserved.</p>
    <div className="space-x-4">
      <a href="https://twitter.com" className="text-white hover:text-teal-400"><i className="fab fa-twitter"></i></a>
      <a href="https://facebook.com" className="text-white hover:text-teal-400"><i className="fab fa-facebook"></i></a>
      <a href="https://linkedin.com" className="text-white hover:text-teal-400"><i className="fab fa-linkedin"></i></a>
    </div>
  </div>
</footer>


  );
}

export default Footer;
