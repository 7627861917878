import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



function Testimonials() {

  const testimonials = [
    {
      name: "Jane Doe",
      feedback: "Nextex helped us improve our AI-driven processes by 50%. Amazing platform!",
      role: "CEO at AI Innovations"
    },
    {
      name: "John Smith",
      feedback: "I love the seamless integration and the powerful features Nextex offers.",
      role: "CTO at Tech Ventures"
    },
    {
      name: "Alice Brown",
      feedback: "Nextex is a game-changer for AI solutions. Highly recommend it!",
      role: "AI Specialist at FutureTech"
    }
  ];
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <section id="testimonials" className="bg-gray-100 py-12">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">What Our Clients Say</h2>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-slide">
              <p className="text-lg italic">"{testimonial.feedback}"</p>
              <div className="mt-4">
                <span className="font-semibold">{testimonial.name}</span>, {testimonial.role}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
