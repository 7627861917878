import React from "react";

function CTASection() {
  return (
    <section id="cta" className="bg-gray-900 text-white text-center py-16">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold mb-6">
          Be the First to Know
        </h2>
        <p className="text-lg mb-6">
          Sign up for early access and stay informed about the future of AI-optimized content.
        </p>
        <form className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-4">
          <input
            type="email"
            placeholder="Enter your email"
            className="py-3 px-4 rounded-lg text-gray-900 w-full md:w-1/3"
          />
          <button
            type="submit"
            className="bg-teal-500 py-3 px-6 rounded-lg hover:bg-teal-400 transition duration-300"
          >
            Get Updates
          </button>
        </form>
      </div>
    </section>
  );
}

export default CTASection;