import React from "react";
import { FaExclamationTriangle, FaCheckCircle } from "react-icons/fa"; // Font Awesome icons

function ProblemSolution() {
  return (
    <section id="problem-solution" className="bg-gradient-to-r from-gray-100 via-white to-gray-100 py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-16">The Problem We Solve</h2>

        {/* Grid Layout for Problem-Solution Pairs */}
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">

          {/* Problem-Solution Pair - Block 1 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow transform hover:scale-105">
            {/* Problem Block */}
            <div className="bg-white p-4 rounded-lg shadow-lg mb-4 flex flex-col items-center">
              <FaExclamationTriangle className="text-2xl text-red-500 mb-2" />
              <h3 className="text-xl font-semibold mb-2">Problem</h3>
              <p className="text-sm text-center">
                Traditional SEO does not cater to AI-driven platforms
              </p>
            </div>
            {/* Solution Block */}
            <div className="bg-teal-500 p-4 rounded-lg text-white flex flex-col items-center shadow-lg">
              <FaCheckCircle className="text-2xl text-white mb-2" />
              <h3 className="text-xl font-semibold mb-2">Solution</h3>
              <p className="text-sm text-center">
                Nextex provides AI-first SEO strategies that optimize content for AI engines
              </p>
            </div>
          </div>

          {/* Problem-Solution Pair - Block 2 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow transform hover:scale-105">
            {/* Problem Block */}
            <div className="bg-teal-500 p-4 rounded-lg text-white flex flex-col items-center shadow-lg ">
              <FaExclamationTriangle className="text-2xl text-white mb-2" />
              <h3 className="text-xl font-semibold mb-2">Problem</h3>
              <p className="text-sm text-center">
                Unstructured data and poor content readability make it difficult for AI to process information
              </p>
            </div>
            {/* Solution Block */}
            <div className="bg-white p-4 rounded-lg shadow-lg mb-4 flex flex-col items-center">
              <FaCheckCircle className="text-2xl text-green-500 mb-2" />
              <h3 className="text-xl font-semibold mb-2">Solution</h3>
              <p className="text-sm text-center">
                Nextex structures content and data for AI readability and semantic understanding
              </p>
            </div>
          </div>

          {/* Problem-Solution Pair - Block 3 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow transform hover:scale-105">
            {/* Problem Block */}
            <div className="bg-white p-4 rounded-lg shadow-lg mb-4 flex flex-col items-center">
              <FaExclamationTriangle className="text-2xl text-red-500 mb-2" />
              <h3 className="text-xl font-semibold mb-2">Problem</h3>
              <p className="text-sm text-center">
                Traditional websites lack integration with AI-driven platforms like voice assistants
              </p>
            </div>
            {/* Solution Block */}
            <div className="bg-teal-500 p-4 rounded-lg text-white flex flex-col items-center shadow-lg">
              <FaCheckCircle className="text-2xl text-white mb-2" />
              <h3 className="text-xl font-semibold mb-2">Solution</h3>
              <p className="text-sm text-center">
                Nextex creates seamless integrations and optimizes content for AI engines, making it accessible to AI-powered services
              </p>
            </div>
          </div>

          {/* Problem-Solution Pair - Block 4 */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow transform hover:scale-105">
            {/* Problem Block */}
            <div className="bg-teal-500 p-4 rounded-lg text-white flex flex-col items-center shadow-lg ">
              <FaExclamationTriangle className="text-2xl text-white mb-2" />
              <h3 className="text-xl font-semibold mb-2">Problem</h3>
              <p className="text-sm text-center">
                Content is not optimized for conversational AI platforms like voice assistants and chatbots
              </p>
            </div>
            {/* Solution Block */}
            <div className="bg-white p-4 rounded-lg shadow-lg mb-4 flex flex-col items-center">
              <FaCheckCircle className="text-2xl text-green-500 mb-2" />
              <h3 className="text-xl font-semibold mb-2">Solution</h3>
              <p className="text-sm text-center">
                Nextex transforms content into question-answer formats and conversational structures that work well with AI interfaces
              </p>
            </div>
          </div>

          {/* Additional Problem-Solution Pair - Block 5 */}
          {/* <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow transform hover:scale-105">
            
            <div className="bg-white p-4 rounded-lg shadow-lg mb-4 flex flex-col items-center">
              <FaExclamationTriangle className="text-2xl text-red-500 mb-2" />
              <h3 className="text-xl font-semibold mb-2">Problem</h3>
              <p className="text-sm text-center">
                Scaling AI solutions across departments can be difficult for many businesses due to data silos and disparate systems.
              </p>
            </div>
            
            <div className="bg-teal-500 p-4 rounded-lg text-white flex flex-col items-center shadow-lg">
              <FaCheckCircle className="text-2xl text-white mb-2" />
              <h3 className="text-xl font-semibold mb-2">Solution</h3>
              <p className="text-sm text-center">
                Nextex provides a scalable AI infrastructure that integrates seamlessly with existing systems, allowing for cross-departmental collaboration.
              </p>
            </div>
          </div> */}
        </div>

        {/* Call to Action */}
        <div className="mt-12">
          <a href="#cta" className="bg-teal-500 text-white font-bold py-3 px-6 rounded hover:bg-teal-600 transition-colors">
            Learn How We Can Help You
          </a>
        </div>
      </div>
    </section>
  );
}

export default ProblemSolution;